<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="row justify-content-center primarybg round-md topPrimaryBox">
               <div class="col-11 col-md-8 col-lg-6 col-xl-5 text-center py-4 py-md-5">
                     <h1 class="mb-0 text-white">{{contentlist.help_centre_content}}</h1>
                     <p class="d-block mt-3 mb-0 text-white">{{contentlist.help_centre_content1}}</p>
                     <div class="position-relative searchBoxDark d-flex justify-content-center mt-5">
                        <input type="text" placeholder="How can we help you?" class="form-control round-sm"  name="" v-model="search" @keyup = "getSearchData()">
                        <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                           <vue-feather class="semibold" type="search" size="22"></vue-feather>
                        </a>
                     </div>
                  </div>
            </div>
         </div>
      </section>
      <section class="blockElement pt-0 pb-4 pb-lg-5">
         <div class="container">
            <h3 class="mb-4 mb-md-5 text-center">{{contentlist.help_centre_content2}}</h3>
            <div class="row justify-content-center">
               <div class="col-12 col-md-6 col-lg-3 mb-4" v-for="value,key,index in faqslist.dataArr" :key="index+1" :val="value">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img  :src="'/assets/images/'+imageJson[key]" alt="Getting Started" class="img-fluid">
                    
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">{{key.toUpperCase()}}</h6>
                        <!-- <p class="m-0 lh-base pt-3 pb-3" v-html="value[0].catDesc"></p> -->
                        <router-link :to="entityName1+'/help-centre/'+value[0].catSlug" class="textLink">{{contentlist.read_more_content}}</router-link>
                     </div>
                  </div>
               </div>
                <!-- <div class="col-12 col-md-6 col-lg-3 mb-4">
                     <div class="faqBox round-sm text-center bordered card-body h-100">
                        <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                           <img src="/assets/images/faq-started.webp" alt="Creating An Account" class="img-fluid">
                        </div>
                        <div class="p-4">
                           <h6 class="m-0 lh-base">Creating An Account</h6>
                           <p class="m-0 lh-base pt-3 pb-3">Learn about our services and how to create your Capital Wallet account.</p>
                           <a href="javascript:void(0);" class="textLink">Read More</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-create-account.webp" alt="Creating An Account" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Creating An Account</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Useful information that will help you sign up and have your account verified.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-manage-account.webp" alt="Managing My Account" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Managing My Account</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Manage your account and make the most out of your wallet.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-manage-portal.webp" alt="Managing My Portal" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Managing My Portal</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Detailed analysis of each page and tips on how to manage your portal.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-digital-wallet.webp" alt="My Digital Wallet" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">My Digital Wallet</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Learn how to accept payments, convert and settle your cryptos and monitor your transaction history.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-security.webp" alt="Privacy And Security" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Privacy And Security</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Learn about our security features and the safety nets that we have put in place for your account.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-integration.webp" alt="Integration" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Integration</h6>
                        <p class="m-0 lh-base pt-3 pb-3">Step-by-step guide to integrate our API to your systems. Created by developers… for developers.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div>
               <div class="col-12 col-md-6 col-lg-3 mb-4">
                  <div class="faqBox round-sm text-center bordered card-body h-100">
                     <div class="d-flex align-items-center justify-content-center m-auto borderedB faqBoxImg">
                        <img src="/assets/images/faq-support.webp" alt="Support" class="img-fluid">
                     </div>
                     <div class="p-4">
                        <h6 class="m-0 lh-base">Support</h6>
                        <p class="m-0 lh-base pt-3 pb-3">All you need to know about the ways you can contact or team for any inquiry you may have.</p>
                        <a href="javascript:void(0);" class="textLink">Read More</a>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </section>
      <section class="blockElement pt-0 mb-3">
         <div class="container text-center">
            <h6 class="mb-4">{{contentlist.help_centre_content3}}</h6>   
            <router-link :to="entityPath+'/contact-us'" class="themeBtn medium">{{contentlist.contact_us_content}}</router-link>
         </div>
      </section>
      <section class="blockElement cryptoGlossary pb-0 aos-init aos-animate pt-0" data-aos="fade-up" data-aos-duration="1000">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-10">
                  <div class="row align-items-center justify-content-center flex-row-reverse text-center text-md-start">
                     <div class="col-12 col-md-6">
                        <h2>{{contentlist.help_centre_content4}}</h2>
                        <p class="mt-3">{{contentlist.help_centre_content5}}</p>
                     </div>
                     <div class="col-12 col-md-6"><img class="d-block mx-auto img-fluid" src="/assets/images/crypto-glossary.webp" alt="The Crypto Glossary Image" title="The Crypto Glossary" width="320" height="415"></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement cryptoSearch borderBG fixedHeader">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <div class="counterSearch d-flex align-items-center flex-row-reverse">
               <ul class="mb-0 numaricValue d-flex align-items-center w-100">
                   <li @click.prevent="selectAlphabet = key" v-for="value,key,index in cryptoGlossary" :key="index" :class="((Object.keys(value).length > 0 && searchGloss) ? 'highlighted' : (Object.keys(value).length == 0 && searchGloss)? 'disabled' : '')"><a href="javascript:void" :class="(selectAlphabet == key) ? 'active' :''">{{key}}</a></li>
                  
               </ul>
               <div class="position-relative counterSearchBox me-0 me-md-4 mt-3 mt-md-0">
                  <input type="text" placeholder="Search terms here" class="form-control rounded-pill" name="" v-model="searchGloss" @keyup = "getSearchGlossaryData()">
                  <a href="javascript:void(0)" class="l-1 counterSearchBoxIcon position-absolute"><vue-feather class="ms-2 semibold" size="20px" type="search"></vue-feather></a>
               </div>
               
            </div>
         </div>
      </div>
   </div>
</section>
<section class="blockElement detailsAlphabet">
   <div class="container">
      <div class="row justify-content-center" v-if="Object.keys(cryptoGlossary).length > 0">
         <div class="col-12 col-md-10">
            <div class="row" v-if="cryptoGlossary[selectAlphabet]">
               <div class="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                  <h2 class="borderFont text-center text-md-start">{{selectAlphabet}}</h2>
               </div>
               <div class="col-12 col-md-8" data-aos="fade-up" data-aos-duration="2000">
                   <div class="card-body mb-3" v-for="value,key,index in cryptoGlossary[selectAlphabet]" :key="index" :class="[{'lightgrayBG' : index == 0}]">
                       <h6 class="bold">{{key}}</h6>
                       <p>{{value}}</p>
                   </div>
               </div>
            </div>
            <div class="row" v-if="!(selectAlphabet in cryptoGlossary)">
               <div class="col-12 col-md-8 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                  <div class="mb-3 lightgreybg round-sm p-3 text-center text-md-start">
                     <h6 class="bold">{{Object.keys(cryptoGlossary)[0]}}</h6>
                     <!-- <p class="mb-0">{{value}}</p> -->
                  </div>
               </div>
               <div class="col-12 col-md-8 aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                   <div class="card-body mb-3 lightgreybg round-sm p-3 text-center text-md-start" v-for="value,key,index in cryptoGlossary[Object.keys(cryptoGlossary)[0]]" :key="index" :class="[{'lightgrayBG' : index == 0}]">
                       <h6 class="bold">{{key}}</h6>
                       <p>{{value}}</p>
                   </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
// import getstarted from "../../components/shared/get_started";
// import AOS from "aos";
import commonAllmixins from "@/plugins/commonAll";
import glossary from "../../../glossary";
export default {
    mixins:[commonAllmixins],
    data() {
        return {
        glossarylist: glossary,
         siteUrl : process.env.VUE_APP_API_BASE_URL,
         search : '',
         searchResult : [],
         pageLoader : false,
         selectAlphabet : 'A',
         searchGloss : '',
         imageJson : {
            'getting-started' : 'faq-started.webp',
            'Creating An Account' : 'faq-create-account.webp',
            'Managing My Account' : 'faq-manage-account.webp',
            'Managing My Portal' : 'faq-manage-portal.webp',
            'My Digital Wallet' : 'faq-digital-wallet.webp',
            'privacy-&-security' : 'faq-security.webp',
            'Integration' : 'faq-integration.webp',
            'Support' : 'faq-support.webp',

            'troubleshooting-tips' : 'trobleshooting_tips.webp',
            'wallets' : 'wallets.webp',
            'payments-&-transfers' : 'payment_transfer.webp',
            'merchants' : 'merchants.webp',
            'announcements' : 'announcements.webp'
         },
          entityName1 : ''
        }
    },
    components: { 
        // getStarted : getstarted,
        
    },
    computed:{
        categories: function () {
              try{
                  return this.$store.getters['Get_Faqs_List'].dataArr

              }catch(e){
                  return {}
              }
        },
        glossaryData(){
            const data= Object.values(this.glossarylist)[0]
            return data;
        },
        cryptoGlossary(){
            if(this.searchGloss.trim() == ''){
                return this.glossaryData
            }else{
              // console.log(this.searchGloss.charAt(0))

                // this.selectAlphabet = this.searchGloss.charAt(0)
                let returnJson = {}
                // console.log(this.glossaryData)
                for(var item in this.glossaryData){
                  // this.selectAlphabet = this.searchGloss.charAt(0)
                 
                    returnJson[item] = {}
                    let itemdata = {}
                    for(var val in this.glossaryData[item]){
                       // console.log(val, this.searchGloss, 'item===>',this.glossaryData[item])
                        if(val.toLowerCase().includes(this.searchGloss.toLowerCase())){
                            itemdata[val] = this.glossaryData[item][val]
                        }
                    }
                    if(Object.keys(itemdata).length > 0){
                        if(item in returnJson){
                            returnJson[item] = itemdata
                        }
                    }
                }
                return returnJson
            }
        }
    },
    methods:{
        call_faqs(){
            let sendData = {
                'globalParams':{},
                'localParams':{'parameter':this}
            }
            this._runGetFaqsList(sendData)
        },
        getSearchData(){
            try{
                if(this.search == ''){                    
                    this.searchResult = []
                }else{                                     
                    this.searchResult =  this.faqslist.searchArr.filter(allCategoryData => {
                        return (`${allCategoryData.articleName}`.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    });                  
                }
            }catch(e){
                this.searchResult = []
            }
        },
        getSearchGlossaryData(){
          this.selectAlphabet = this.searchGloss.charAt(0).toUpperCase()
        }
    },
    created(){
        this.call_faqs()
        this.entityName1 = ((this.entityName == 'global')?'/global' : '/au');

    }
}
</script>