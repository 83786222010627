export default [
  {
    'A': {
      'Absolute Advantage':
        'Absolute advantage is the ability of an individual, company, region, or country to produce a greater quantity of a good or service with the same quantity of inputs per unit of time, or to produce the same quantity of a good or service per unit of time using a lesser quantity of inputs, than its competitors.',

      'Active Management':
        "The term active management implies that a professional money manager or a team of professionals is tracking the performance of a client's investment portfolio and regularly making buy, hold, and sell decisions about the assets in it. The goal of the active manager is to outperform the overall market.",

      'Ad Hoc':
        "The term active management implies that a professional money manager or a team of professionals is tracking the performance of a client's investment portfolio and regularly making buy, hold, and sell decisions about the assets in it. The goal of the active manager is to outperform the overall market.",
      'Address':
        'Much like an address in the real world, or a website, an address on the blockchain relates to the string of text given to identify a particular place or user. More specifically it is the string of text that designates the location of a particular wallet on the blockchain, that can be used to send or receive digital assets from.',
      'Algorithm':
        'an algorithm is a set of well-defined steps or rules that you need to follow to obtain a pre-determined result. For instance, when we talk about algorithms in computer programming, we already have our input and we know the expected output. Now, an algorithm would be all the defined steps to follow on the input to get the desired output.',
      'All or none (AON)':
        'It is a common type of contingent order that specifies the entire size of the order must be filled and that partial fills will not be accepted. AON orders thus involve a directive used on a buy or sell order that instructs the broker to fill the order completely or not at all. If there are too few shares available to fill the order entirely, the order is cancelled.',
      'All Time High':
        'The term “All-Time High” relates to the highest price that an asset has achieved on an exchange, for the current trading pair that is being referenced.  ',
      'Asset Allocation':
        "Asset allocation is an investment strategy that aims to balance risk and reward by apportioning a portfolio's assets according to an individual's goals, risk tolerance, and investment horizon. The three main asset classes - equities, fixed-income, and cash and equivalents - have different levels of risk and return, so each will behave differently over time. ",
      'Alpha':
        "Alpha (α) is a term used in investing to describe an investment strategy's ability to beat the market, or its 'edge.' Alpha is thus also often referred to as “excess return” or “abnormal rate of return,” which refers to the idea that markets are efficient, and so there is no way to systematically earn returns that exceed the broad market as a whole.",
      'Altcoin':
        'Altcoins are cryptocurrencies other than Bitcoin (BTCUSD). They share characteristics with Bitcoin but are also different in other ways. For example, some altcoins use a different consensus mechanism to produce blocks or validate transactions. Or they distinguish themselves from Bitcoin by providing new or additional capabilities, such as smart contracts or low price volatility.',
      'Angel Investor':
        "An angel investor (also known as a private investor, seed investor or angel funder) is a high-net-worth individual who provides financial backing for small startups or entrepreneurs, typically in exchange for ownership equity in the company. Often, angel investors are found among an entrepreneur's family and friends. The funds that angel investors provide may be a one-time investment to help the business get off the ground or an ongoing injection to support and carry the company through its difficult early stages.",
      'Anti-Money Laundering':
        'Anti-money laundering (AML) refers to the laws, regulations, and procedures intended to prevent criminals from disguising illegally obtained funds as legitimate income.',
      'An application programming interface (API)':
        'It is a set of programming codes that queries data, parse responses, and sends instructions between one software platform and another.',
      'Arbitrage':
        'Arbitrage is the practice of buying and selling assets over two or more markets as a way to take advantage of different prices. For instance, a trader could buy a particular asset in one market and quickly sell the same asset in another market, at a higher price.',
      'Application-specific integrated circuit (ASIC)':
        "It is an integrated circuit chip that has been designed for a specific purpose. An ASIC miner refers to a computerised device or hardware that uses ASICs for the sole purpose of 'mining' digital currency. Generally, each ASIC miner is constructed to mine a specific digital currency. So, a Bitcoin ASIC miner can mine only bitcoin. ",
      'Ask Price':
        'The ask price represents the minimum price that a seller is willing to take for a security. ',
      'Asset Management':
        'Asset management is the practice of increasing total wealth over time by acquiring, maintaining, and trading investments that have the potential to grow in value.',
      'Atomic Swap':
        "An atomic swap is an exchange of cryptocurrencies from separate blockchains. The swap is conducted between two entities without a third party's involvement. The idea is to remove centralised intermediaries like regulated exchanges and give token owners total control.",
      'Auction':
        'An auction is a sales event wherein potential buyers place competitive bids on assets or services either in an open or closed format. Auctions are popular because buyers and sellers believe they will get a good deal buying or selling assets.',
    },
    'B': {
      'Bag Holder':
        'A bag holder is an informal term used to describe an investor who holds a position in a security that decreases in value until it descends into worthlessness. In most cases, the bag holder stubbornly retains their holding for an extended period, during which time the value of the investment goes to zero.',

      'Bear Market':
        'A bear market is when a market experiences prolonged price declines. It typically describes a condition in which securities prices fall 20% or more from recent highs amid widespread pessimism and negative investor sentiment.',

      'Benchmark':
        'A benchmark is a standard against which the performance of a security, mutual fund, or investment manager can be measured. Generally, broad market and market-segment stock and bond indexes are used for this purpose.',

      'BEP': 'The breakeven point (break-even price) for a trade or investment is determined by comparing the market price of an asset to the original cost; the breakeven point is reached when the two prices are equal.',

      'Beta (Coefficient)':
        'In finance, a beta coefficient is a tool used to measure the volatility of a certain asset in relation to the volatility of the overall market or a particular portfolio. In other terms, beta can be used to assess the risk of an investment in correlation to a benchmark, which can be represented by a broad market index or by a specific portfolio.',

      'Beta (Release)':
        'In computer science, the term beta refers to the second stage of a software development cycle, and it comes right after the alpha stage. Beta consists of a phase where the software already has all the major features and functions working, but its efficiency, usability, and security still needs further testing.',

      'Bid Price':
        'The bid price represents the maximum price that a buyer is willing to pay for a share of stock or other security.',

      'Bid-Ask Spread':
        'A bid-ask spread is the amount by which the ask price exceeds the bid price for an asset in the market. The bid-ask spread is essentially the difference between the highest price that a buyer is willing to pay for an asset and the lowest price that a seller is willing to accept.',

      'Bitcoin':
        'Bitcoin is a decentralised digital currency created in January 2009. It follows the ideas set out in a white paper by the mysterious and pseudonymous Satoshi Nakamoto.The identity of the person or persons who created the technology is still a mystery. Bitcoin offers the promise of lower transaction fees than traditional online payment mechanisms do, and unlike government-issued currencies, it is operated by a decentralised authority.',

      'Bitcoin Core':
        'Bitcoin Core is the leading implementation of the software enabling users to interact with the Bitcoin network. It is not owned by any single business or organisation but is instead updated and reviewed by a community of worldwide developers.',

      'Black Swan Event':
        'A black swan is an unpredictable event that is beyond what is normally expected of a situation and has potentially severe consequences. Black swan events are characterised by their extreme rarity, severe impact, and the widespread insistence they were obvious in hindsight.',

      'Block':
        'Blocks are data structures within the blockchain database, where transaction data in a cryptocurrency blockchain are permanently recorded. A block records some or all of the most recent transactions not yet validated by the network. Once the data are validated, the block is closed. Then, a new block is created for new transactions to be entered into and validated.',

      'Block Header':
        'A block header is used to identify a particular block on an entire blockchain and is hashed repeatedly to create proof of work for mining rewards. A blockchain consists of a series of various blocks that are used to store information related to transactions that occur on a blockchain network. Each of the blocks contains a unique header, and each such block is identified by its block header hash individually.',

      'Block Height':
        'The block height of a particular block is defined as the number of blocks preceding it in the blockchain. A blockchain is an encrypted database that records a ledger of transactions sequentially in data structures known as blocks. Blockchains are used as the underlying technology for cryptocurrencies such as Bitcoin.',

      'Block rewards':
        'They are new bitcoins awarded to cryptocurrency miners for being the first to solve a complex maths problem and creating a new block of verified bitcoin transactions. The miners use networks of computers to do this, and every time a new block is created it is verified by all the other competing miners. Then a new maths problem is introduced and the miners start over.',

      'Blockchain':
        'A blockchain is a distributed database that is shared among the nodes of a computer network. As a database, a blockchain stores information electronically in digital format. Blockchains are best known for their crucial role in cryptocurrency systems, such as Bitcoin, for maintaining a secure and decentralised record of transactions. The innovation with a blockchain is that it guarantees the fidelity and security of a record of data and generates trust without the need for a trusted third party.',

      'Bollinger Bands':
        'In the 1980s, John Bollinger, a long-time technician of the markets, developed the technique of using a moving average with two trading bands above and below it. Unlike a percentage calculation from a normal moving average, Bollinger Bands simply add and subtract a standard deviation calculation.',

      'Bounty ':
        'In the world of cryptocurrency, bounty programs are used by coin developers to incentive actions before the initial coin offering (ICO).',

      'Breakeven Multiple':
        'Breakeven Multiple is the value by which the current price of a coin or asset needs to be multiplied by to reach its Breakeven Point (BEP). The Breakeven Point is the initial acquisition cost paid by a trader or investor (including trading fees). Therefore, when the market price of an asset drops below the price paid, the trader would need it to rise again in order to break even, so they can close their positions without gains or losses.',

      'Breakout':
        'A breakout refers to when the price of an asset moves above a resistance area, or moves below a support area. Breakouts indicate the potential for the price to start trending in the breakout direction. For example, a breakout to the upside from a chart pattern could indicate the price will start trending higher. Breakouts that occur on high volume (relative to normal volume) show greater conviction which means the price is more likely to trend in that direction.',

      'Bull Market':
        "A bull market is the condition of a financial market in which prices are rising or are expected to rise. The term 'bull market' is most often used to refer to the stock market but can be applied to anything that is traded, such as bonds, real estate, currencies, and commodities.",

      'Buy and sell walls':
        'The concept of a buy wall or a sell wall is dependent upon the way that many cryptocurrency transactions are facilitated. In many cases, transactions are made via an order book, whereby a buyer indicates a particular price at which he or she would like to buy a given number of units of the currency.',
    },

    'C': {
      'Candidate Block':
        'A candidate block is a block that a mining node (miner) is trying to mine in order to receive the block reward. So a candidate block may be described as a temporary block that will be either validated or discarded by the network.',

      'Candlestick':
        "A candlestick is a type of price chart used in technical analysis that displays the high, low, open, and closing prices of a security for a specific period. It originated from Japanese rice merchants and traders to track market prices and daily momentum hundreds of years before becoming popularised in the United States. The wide part of the candlestick is called the 'real body' and tells investors whether the closing price was higher or lower than the opening price (black/red if the stock closed lower, white/green if the stock closed higher).",

      'Capitulation':
        'Capitulation is when investors give up any previous gains in any security or market by selling their positions during periods of declines. Capitulation can happen at any time, but typically happens during high volume trading and extended declines for securities. A market correction or bear market often leads investors to capitulate or panic sell. The term is derived from a military term which refers to surrender.',

      'Censorship Resistance':
        'Cryptocurrencies are decentralized, which means they don’t need a central authority to function. Cryptography is used to secure transactions and governs the creation of new cryptocurrency units. Hence, the number of bitcoins cannot be increased or restricted by any monetary policy. Cryptocurrencies are censorship-resistant since they are not controlled or frozen by any individual, government, or organisation. Transactions cannot be reversed or censored.',

      'Central Bank':
        'A central bank is a financial institution given privileged control over the production and distribution of money and credit for a nation or a group of nations. In modern economies, the central bank is usually responsible for the formulation of monetary policy and the regulation of member banks.',

      'Centralised Market':
        'A centralised market is a financial market structure that consists of having all orders routed to one central exchange with no other competing market. The quoted prices of the various securities listed on the exchange represent the only price that is available to investors seeking to buy or sell the specific asset.',

      'Circulating Supply':
        'Circulating supply is the amount of coins or tokens that’s been mined or generated. It’s the approximate number that’s currently in public hands and circulating in the market',

      'Cloud':
        'In computer science, the term cloud refers to a shared pool of resources, which are made available to multiple users through the Internet. Such resources are usually related to data storage and computing power, but may also include different types of services, applications, networks, and servers.',

      'Coin': 'Digital coin is a form of currency that is available only in digital or electronic form. It is also called digital money, electronic money, electronic currency, or cybercash.',

      'Collateral':
        'The term collateral refers to an asset that a lender accepts as security for a loan. Collateral may take the form of real estate or other kinds of assets, depending on the purpose of the loan. The collateral acts as a form of protection for the lender. That is, if the borrower defaults on their loan payments, the lender can seize the collateral and sell it to recoup some or all of its losses.',

      'Colocation':
        'Colocation (or Colo) refers to the establishment of a shared facility to store IT equipment and specialised hardware of multiple individuals or companies.',

      'Commodity Futures Trading Commission':
        'The Commodity Futures Trading Commission (CFTC) is an independent federal agency that regulates the derivatives markets, including futures contracts, options, and swaps, in the United States. Its goals include the promotion of competitive and efficient markets and the protection of investors against manipulation, abusive trade practises, and fraud. ',

      'Confirmation Time':
        'It is defined as an elapsed time between a blockchain transaction submission to the network, and the period it’s finally recorded to the confirmed block. In simple words, it represents the total time any user has to wait till their transaction is collected and confirmed by the miner mode.',

      'Confluence':
        "Confluence is the combination of multiple strategies and ideas into one complete strategy. Confluence occurs when two or more separate ideas or strategies are used together to form a comprehensive investment strategy that is in line with an investor's risk profile and goals.",

      'Consumer Price Index (CPI)':
        'The Consumer Price Index (CPI) is a measure that examines the weighted average of prices of a basket of consumer goods and services, such as transportation, food, and medical care. It is calculated by taking price changes for each item in the predetermined basket of goods and averaging them. Changes in the CPI are used to assess price changes associated with the cost of living.',

      'Credentials':
        'It is referred to as personalised information. Such as; Username, password, email address etc. ',

      'Cryptocurrency':
        'A cryptocurrency is a digital or virtual currency that is secured by cryptography, which makes it nearly impossible to counterfeit or double-spend. Many cryptocurrencies are decentralised networks based on blockchain technology—a distributed ledger enforced by a disparate network of computers. A defining feature of cryptocurrencies is that they are generally not issued by any central authority, rendering them theoretically immune to government interference or manipulation.',

      'Cryptography':
        'Cryptography is associated with the process of converting ordinary plain text into unintelligible text and vice-versa. It is a method of storing and transmitting data in a particular form so that only those for whom it is intended can read and process it.',

      'Custody':
        'It can be referred to as ownership of someone’s funds or assets.',
    },

    'D': {
      'Dead Cat Bounce':
        'A dead cat bounce is a temporary, short-lived recovery of asset prices from a prolonged decline or a bear market that is followed by the continuation of the downtrend. Frequently, downtrends are interrupted by brief periods of recovery—or small rallies—during which prices temporarily rise.',

      'Decentralised Application':
        "Decentralised applications (dApps) are digital applications or programs that exist and run on a blockchain or peer-to-peer (P2P) network of computers instead of a single computer. DApps (also called 'dapps') are outside the purview and control of a single authority. DApps—which are often built on the Ethereum platform—can be developed for a variety of purposes including gaming, finance, and social media.",

      'Decentralised Autonomous Cooperative':
        'A Decentralised Autonomous Cooperative or DAC, pertains to an organisation controlled by shareholders, not a central authority. It is sometimes called Decentralised Autonomous Corporation or DAC. It is represented by rules that are coded into a transparent computer program that is controlled by the members of the organisations. It is not, in any way, influenced by a central government.',

      'Decentralised Exchange':
        'A Decentralised Exchange is a platform where users may trade cryptocurrencies without the use of an intermediary.',

      'Decentralised Finance':
        'Decentralised finance (DeFi) is an emerging financial technology based on secure distributed ledgers similar to those used by cryptocurrencies. The system removes the control banks and institutions have on money, financial products, and financial services.',

      'Decryption':
        'The conversion of encrypted data into its original form is called Decryption. It is generally a reverse process of encryption. It decodes the encrypted information so that an authorised user can only decrypt the data because decryption requires a secret key or password.',

      'Deep Web':
        'The deep web refers to parts of the Internet not fully accessible through standard search engines like Google, Yahoo, and Bing. The deep web includes pages that were not indexed, fee-for-service (FFS) sites, private databases, and the dark web.',

      'Delisting':
        'Delisting is the removal of a listed security from a stock exchange. The delisting of a security can be voluntary or involuntary and usually results when a company ceases operations, declares bankruptcy, merges, does not meet listing requirements, or seeks to become private.',

      'Difficulty Bomb':
        'Ethereum is a cryptocurrency network that, like others, relies on a consensus mechanism that uses large amounts of energy and computational power. This encourages scalability and centralization of the technology into large mining farms created by people or businesses with significant assets. However, this centralization goes against the original tenets behind cryptocurrency.',

      'Divergence':
        'In finance, divergence occurs when an asset’s market price is moving in the opposite direction of another piece of data, usually represented by a technical analysis indicator. Divergences are used by traders and investors in an attempt to determine if a market trend is getting weaker, which may lead to a consolidation period or a trend reversal.',

      'Diversification':
        'Diversification is a risk management strategy that mixes a wide variety of investments within a portfolio. A diversified portfolio contains a mix of distinct asset types and investment vehicles in an attempt at limiting exposure to any single asset or risk. The rationale behind this technique is that a portfolio constructed of different kinds of assets will, on average, yield higher long-term returns and lower the risk of any individual holding or security.',

      'Double Spending':
        'Double-spending is the risk that a cryptocurrency can be used twice or more. Transaction information within a blockchain can be altered if specific conditions are met. The conditions allow modified blocks to enter the blockchain; if this happens, the person that initiated the alteration can reclaim spent coins.',
    },

    'E': {
      'Encryption ':
        "Encryption is a means of securing digital data using one or more mathematical techniques, along with a password or 'key' used to decrypt the information. The encryption process translates information using an algorithm that makes the original information unreadable. The process, for instance, can convert an original text, known as plaintext, into an alternative form known as ciphertext. When an authorised user needs to read the data, they may decrypt the data using a binary key.",

      'Enterprise Ethereum Alliance':
        'The Enterprise Ethereum Alliance  (or EEA), launched in February 2017, brings together various start-ups, Fortune 500 companies, technology vendors, academics, and Ethereum subject matter experts to work on Ethereum as an enterprise-grade technology.',

      'Exchange':
        'An exchange is a marketplace where securities, commodities, derivatives and other financial instruments are traded. The core function of an exchange is to ensure fair and orderly trading and the efficient dissemination of price information for any securities trading on that exchange. Exchanges give companies, governments, and other groups a platform from which to sell securities to the investing public.',
    },

    'F': {
      'FakeOut':
        'Fakeout is a term used in technical analysis to refer to a situation in which a trader enters into a position in anticipation of a future transaction signal or price movement, but the signal or movement never develops and the asset moves in the opposite direction.',

      'Fear, Uncertainty and Doubt':
        'Fear, uncertainty and doubt (FUD) is a technique used in business that attempts to create a negative impression and opinion of a competing organisation or individual.',

      'Fiat': 'Fiat money is a government-issued currency that is not backed by a physical commodity, such as gold or silver, but rather by the government that issued it. The value of fiat money is derived from the relationship between supply and demand and the stability of the issuing government, rather than the worth of a commodity backing it.',

      'Fill or Kill Order':
        'Fill or kill (FOK) is a conditional type of time-in-force order used in securities trading that instructs a brokerage to execute a transaction immediately and completely or not at all. This type of order is most often used by active traders and is usually for a large quantity of stock. The order must be filled in its entirety or else cancelled (killed).',

      'First Mover Advantage':
        'A first mover is a service or product that gains a competitive advantage by being the first to market with a product or service. Being first typically enables a company to establish strong brand recognition and customer loyalty before competitors enter the arena. Other advantages include additional time to perfect its product or service and setting the market price for the new item.',

      'Fiscal Policy':
        'Fiscal policy refers to the use of government spending and tax policies to influence economic conditions, especially macroeconomic conditions, including aggregate demand for goods and services, employment, inflation, and economic growth.',

      'Forced Liquidation':
        'Forced selling or forced liquidation usually entails the involuntary sale of assets or securities to create liquidity in the event of an uncontrollable or unforeseen situation. Forced selling is normally carried out in reaction to an economic event, personal life change, company regulation, or legal order.',

      'Forex':
        'The forex market allows participants, such as banks and individuals, to buy, sell or exchange currencies for both hedging and speculative purposes. The foreign exchange (forex) market is the largest financial market in the world and is made up of banks, commercial companies, central banks, investment management firms, hedge funds, retail forex brokers, and investors.',

      'Full Node':
        'A full node is a program that fully validates transactions and blocks. Almost all full nodes also help the network by accepting transactions and blocks from other full nodes, validating those transactions and blocks, and then relaying them to further full nodes.',

      'Fundamental Analysis':
        "Fundamental analysis (FA) is a method of measuring a security's intrinsic value by examining related economic and financial factors. Fundamental analysts study anything that can affect the security's value, from macroeconomic factors such as the state of the economy and industry conditions to microeconomic factors like the effectiveness of the company's management.",

      'Futures Contract':
        'A futures contract is a legal agreement to buy or sell a particular commodity asset, or security at a predetermined price at a specified time in the future. Futures contracts are standardised for quality and quantity to facilitate trading on a futures exchange.',
    },

    'G': {
      'Gas': 'Gas refers to the fee, or pricing value, required to successfully conduct a transaction or execute a contract on the Ethereum blockchain platform. Priced in small fractions of the cryptocurrency ether (ETH), commonly referred to as gwei and sometimes also called nanoeth, the gas is used to allocate resources of the Ethereum virtual machine (EVM) so that decentralised applications such as smart contracts can self-execute in a secured but decentralised fashion.',

      'Gas Limit':
        "The term gas limit refers to the maximum price a cryptocurrency user is willing to pay when sending a transaction, or performing a smart contract function, in the Ethereum blockchain. These fees are calculated in gas units, and the gas limit defines the maximum value that the transaction or function can 'charge' or take from the user. As such, the gas limit works as a security mechanism that prevents high fees from being incorrectly charged due to a bug or error in a smart contract.",

      'Genesis Block':
        'A Genesis Block is the name given to the first block a cryptocurrency, such as Bitcoin, ever mined. A blockchain consists of a series of so-called blocks that are used to store information related to transactions that occur on a blockchain network. Each of the blocks contains a unique header, and each such block is identified by its block header hash individually.',

      'Golden Cross':
        "A golden cross is a chart pattern in which a relatively short-term moving average crosses above a long-term moving average. The golden cross is a bullish breakout pattern formed from a crossover involving a security's short-term moving average (such as the 15-day moving average) breaking above its long-term moving average (such as the 50-day moving average) or resistance level. As long-term indicators carry more weight, the golden cross indicates a bull market on the horizon and is reinforced by high trading volumes.",

      'Grey Swan':
        'Grey swan is a term used to describe a potentially very significant event whose possible occurrence may be predicted beforehand but whose probability is considered small. In other words, it is a risk with a potentially large impact but a low perceived likelihood of happening. Because there is a slight chance the event will occur it should be anticipated, particularly as it could shake up the world economy and stock market.',

      'Gwei': 'Gwei is a portmanteau (a blend of words) of giga and wei. Gwei is a denomination of the cryptocurrency ether (ETH), the digital coin used on the Ethereum network. Ethereum is a blockchain platform, like Bitcoin, where users transact to buy and sell goods and services without a middle man or interference from a third party.',
    },

    'H': {
      'Hacker':
        ' In popular terminology, though, a hacker is a person who seeks to exploit the vulnerabilities of a computer system or network. These are also referred to as security hackers.',

      'Halving':
        "One of the most pivotal events on Bitcoin's blockchain is halving. It induces inflation in the cryptocurrency's price by reducing the number of bitcoin in circulation and increasing demand for Bitcoin. Bitcoin halving has implications for all stakeholders within Bitcoin's ecosystem.",

      'Hard Cap':
        "A hard cap is the limit placed by a blockchain's code on the absolute maximum supply of a particular cryptocurrency, A hard cap doesn’t allow any further creation/circulation of its units. It is generally understood to be positive in nature as it creates scarcity, which drives up the value of each token.",

      'Hash': 'The backbone of a cryptocurrency is the blockchain, which is a global ledger formed by linking together individual blocks of transaction data. The blockchain only contains validated transactions, which prevents fraudulent transactions and double spending of the currency. The resulting encrypted value is a series of numbers and letters that do not resemble the original data and is called a hash.',

      'Hash Rate':
        'Hash rate is the speed at which a cryptocurrency mining device operates.',

      'Hashed TimeLock Contract':
        'A Hashed Timelock Contract (HTLC) is a type of smart contract used in blockchain applications to eliminate counterparty risk by enabling the implementation of time-bound transactions. In practical terms, this means that recipients of a transaction have to acknowledge payment by generating cryptographic proof within a certain timeframe. Otherwise, the transaction does not take place.',

      'High Frequency Trading':
        'High-frequency trading (HFT) is an automated trading platform that large investment banks, hedge funds, and institutional investors employ. It uses powerful computers to transact a large number of orders at extremely high speeds.',

      'HODL': "HODL is a term derived from a misspelling of 'hold,' in the context of buying and holding Bitcoin and other cryptocurrencies. It's also commonly come to stand for 'hold on for dear life' among crypto investors",
    },

    'I': {
      'Iceberg Order':
        " Iceberg orders are large single orders that have been divided into smaller limit orders, usually through the use of an automated program, for the purpose of hiding the actual order quantity. The term 'iceberg' comes from the fact that the visible lots are just the 'tip of the iceberg' given the greater number of limit orders ready to be placed. They are also sometimes referred to as reserve orders.",

      'Immutability':
        'Immutability means unchangeability. In computer science, an immutable object is an object whose state cannot be altered after its creation. It is one of the features of bitcoin and blockchain technology.',

      'Index':
        'A system that measures the value of something in contrast to its previous values or another defined standard or benchmark. It may also be described as a financial instrument used to represent a group of individual prices or data points.',

      'Initial Coin Offering':
        "An initial coin offering (ICO) is the cryptocurrency industry's equivalent to an initial public offering (IPO). A company seeking to raise money to create a new coin, app, or service can launch an ICO as a way to raise funds.",

      'Initial Exchange Offering':
        'The initial offering date is when a stock or security is first made available for public purchase. The initial offering date is part of the process for an initial public offering (IPO), which is when a private company issues new shares of stock or securities to public investors. Initial offering dates can be advertised for all types of securities, with stocks and managed funds being two of the most common.',

      'Initial Public Offering':
        'An initial public offering (IPO) refers to the process of offering shares of a private corporation to the public in a new stock issuance. An IPO allows a company to raise capital from public investors. The transition from a private to a public company can be an important time for private investors to fully realise gains from their investment as it typically includes a share premium for current private investors. Meanwhile, it also allows public investors to participate in the offering.',

      'Isolated Margin':
        'Isolated margin is the initial margin applied to an open order position. The benefit of isolated margin is that traders can control precisely how much capital they are willing to risk in order to limit potential losses in case the market moves against an open position.',
    },

    'J': {},

    'K': {
      'The Know Your Client or Know Your Customer (KYC)':
        "It is a standard in the investment industry that ensures investment advisors know detailed information about their clients' risk tolerance, investment knowledge, and financial position. KYC protects both clients and investment advisors.",
    },

    'L': {
      'Latency':
        'Latency in computing refers to the time delay between an input and the received output. In cryptocurrencies, latency can refer to two different time delays. The first is the latency in the network of a blockchain, and the second is the latency on an exchange.',

      'Law Of Demand':
        'The law of demand is one of the most fundamental concepts in economics. It works with the law of supply to explain how market economies allocate resources and determine the prices of goods and services that we observe in everyday transactions.',

      'Ledger':
        'A ledger may be defined as either a physical book or a digital computer file where monetary and financial transactions are noted down and recorded - either in the form of debits or in the form of credits.',

      'Linux':
        'It is an operating system, based on UNIX, that runs on many different hardware platforms and whose source code is available to the public.',

      'Liquidity':
        'Liquidity refers to the efficiency or ease with which an asset or security can be converted into ready cash without affecting its market price. The most liquid asset of all is cash itself.',

      'Listing':
        "When an exchange offers trading pairs for a particular asset, this action is known as “listing” the asset. In traditional markets, this means that a company's shares (or material asset) are available to be traded on that particular stock exchange.",
    },

    'M': {
      'Maker':
        'You become a “maker” when you place an order and it does not trade immediately, so your order stays in the order book and waits for someone else to fill/match with it later.',

      'Malware':
        'Malware is a collective term of viruses, worms, Trojans, etc. Hackers may also use some other harmful software to get into your system to destroy and collect sensitive information.',

      'Margin Trading':
        'Margin trading is the act of borrowing funds from a broker with the aim of investing in financial securities. The purchased stock serves as collateral for the loan. The primary reason behind borrowing money is to utilise more capital to invest and, by extension, the potential for more profits.',

      'Market Capitalization':
        "Market capitalization, or 'market cap', is the aggregate market value of a company represented in a dollar amount. Since it represents the “market” value of a company, it is computed based on the current market price (CMP) of its shares and the total number of outstanding shares. Market cap is also used to compare and categorise the size of companies among investors and analysts.",

      'Market Momentum':
        "Momentum is the rate of acceleration of a security's price—that is, the speed at which the price is changing. Market momentum refers to the aggregate rate of acceleration for the broader market as a whole.",

      'Market Order':
        'A market order is an instruction by an investor to a broker to buy or sell stock shares, bonds, or other assets at the best available price in the current financial market.',

      'Maximum Supply':
        'The maximum supply is defined as the maximum number of cryptocurrency coins or tokens that will ever exist. It includes the total number of coins or tokens that can ever be mined or created, as such, the coins do not necessarily need to exist currently or be in circulation to be counted towards the maximum supply.',

      'Mining':
        "Bitcoin mining is the process of creating new bitcoin by solving puzzles. It consists of computing systems equipped with specialised chips competing to solve mathematical puzzles. The first bitcoin miner (as these systems are called) to solve the puzzle is rewarded with bitcoin. The mining process also confirms transactions on the cryptocurrency's network and makes them trustworthy.",

      'Mining Farm':
        'Mining is the process through which cryptocurrency transactions are gathered, verified and recorded into a digital ledger known as blockchain.',

      'Monetary Policy':
        "Monetary policy is a set of tools that a nation's central bank has available to promote sustainable economic growth by controlling the overall supply of money that is available to the nation's banks, its consumers, and its businesses.",

      'Multisignature':
        'A Multisignature is a system in which more than one private key is required to authorise a single transaction. It is generally used to entrust the responsibility of the possession and spending of cryptocurrencies to multiple persons instead of one. A multisignature can be compared to a check that requires multiple handwritten signatures to be valid.',
    },

    'N': {
      'Non-Fungible Token':
        'Non-fungible tokens or NFTs are cryptographic assets on a blockchain with unique identification codes and metadata that distinguish them from each other. Unlike cryptocurrencies, they cannot be traded or exchanged at equivalency. This differs from fungible tokens like cryptocurrencies, which are identical to each other and, therefore, can be used as a medium for commercial transactions.',
    },

    'O': {
      'Off-chain Transactions':
        'Off-chain transactions refer to those transactions occurring on a cryptocurrency network that move the value outside of the blockchain. Due to their zero/low cost, off-chain transactions are gaining popularity, especially among large participants.',

      'Offshore Account':
        "The term offshore refers to a location outside of one's home country. The term is commonly used in the banking and financial sectors to describe areas where regulations are different from the home country. Offshore locations are generally island nations, where entities set up corporations, investments, and deposits. Companies and individuals (typically those with a high net worth) may move offshore for more favourable conditions, including tax avoidance, relaxed regulations, or asset protection. Although offshore institutions can also be used for illicit purposes, they aren't considered illegal.",

      'One Cancels The Other Order (OCO)':
        'A one-cancels-the-other (OCO) order is a pair of conditional orders stipulating that if one order executes, then the other order is automatically cancelled. An OCO order often combines a stop order with a limit order on an automated trading platform. When either the stop or limit price is reached and the order is executed, the other order is automatically cancelled. Experienced traders use OCO orders to mitigate risk and enter the market.',

      'Open Source Software':
        'Open source refers to a software program or platform with source code that is readily accessible and which can be modified or enhanced by anyone. Open source access grants users of an application permission to fix broken links, enhance the design, or improve the original code.',

      'Order Book':
        'The term order book refers to an electronic list of buy and sell orders for a specific security or financial instrument organised by price level. An order book lists the number of shares being bid on or offered at each price point, or market depth. It also identifies the market participants behind the buy and sell orders, though some choose to remain anonymous. These lists help traders and also improve market transparency because they provide valuable trading information.',
    },

    'P': {
      'Paper Wallet':
        'A paper wallet is a piece of paper with your private and public keys printed out. Some paper wallets might also have a scannable barcode created by an app. It is a way to store and take access to your cryptocurrency offline. When you print your keys, they are removed from the cryptocurrency network, but the tokens remain; however, they are inaccessible without your keys.',

      'Passive Management':
        "Passive management is a style of management associated with mutual and exchange-traded funds (ETF) where a fund's portfolio mirrors a market index. Passive management is the opposite of active management in which a fund's manager(s) attempt to beat the market with various investing strategies and buying/selling decisions of a portfolio's securities. Passive management is also referred to as 'passive strategy,' 'passive investing,' or ' index investing.'",

      'Peer to Peer (P2P service)':
        'A peer-to-peer (P2P) service is a decentralised platform whereby two individuals interact directly with each other, without intermediation by a third party. Instead, the buyer and the seller transact directly with each other via the P2P service. The P2P platform may provide services such as search, screening, rating, payment processing, or escrow.',

      'Pegged Currency':
        'A currency where the price is designed to remain the same as a designated asset. For example: 1 USDT is pegged to 1 USD. Also referred to as a stablecoin',

      'Phishing':
        'Phishing is a method of identity theft that relies on individuals unwittingly volunteering personal details or information that can then be used for nefarious purposes. It is often carried out through the creation of a fraudulent website, email, or text appearing to represent a legitimate firm.',

      'Price Action':
        "Price action is the movement of a security's price plotted over time. Price action forms the basis for all technical analysis of a stock, commodity or other asset chart. Many short-term traders rely exclusively on price action and the formations and trends extrapolated from it to make trading decisions.",

      'Private Key':
        'A private key is a secret number that is used in cryptography, similar to a password. In cryptocurrency, private keys are also used to sign transactions and prove ownership of a blockchain address.',
    },

    'Q': {},

    'R': {
      'Resistance':
        'Resistance, or a resistance level, is the price at which the price of an asset meets pressure on its way up by the emergence of a growing number of sellers who wish to sell at that price. Resistance levels can be short-lived if new information comes to light that changes the overall market’s attitude toward the asset, or they can be long-lasting. In terms of technical analysis, the simple resistance level can be charted by drawing a line along the highest highs for the time period being considered.',

      'Return Of Investment':
        'Return on investment (ROI) is a performance measure used to evaluate the efficiency or profitability of an investment or compare the efficiency of a number of different investments. ROI tries to directly measure the amount of return on a particular investment, relative to the investment’s cost.',
    },

    'S': {
      'Smart Contract':
        'A self-executing contract with the terms and conditions of an agreement directly written into code. Smart contracts operate on blockchain networks, automatically executing actions when specific conditions are met.',
      'Stable Coin':
        'A type of cryptocurrency designed to maintain a stable value, usually pegged to a fiat currency or a basket of assets. Stablecoins are commonly used to mitigate price volatility in the crypto market.',
      'Supply Chain':
        'A blockchain-based system that tracks and verifies the flow of goods or products through various stages of production, distribution, and consumption. It ensures transparency and authenticity within the supply chain process.',
    },

    'T': {
      'Taker':
        "In a cryptocurrency exchange, a taker refers to a trader who places an order that matches an existing order on the order book. Takers take liquidity from the market and pay the market's trading fee.",
      'Ticker':
        'A symbol or abbreviation used to represent a particular cryptocurrency in the financial markets. Tickers are commonly used for quick identification of assets in trading platforms and financial charts.',
      'Token ':
        'A digital asset issued on a blockchain platform, representing ownership of a specific utility or asset. Tokens can serve various purposes, such as access to services, voting rights, or as an investment.',
      'Total Supply':
        'The maximum number of tokens or coins that will ever be created for a particular cryptocurrency. Total supply helps determine the scarcity and potential value of a crypto asset.',
    },

    'U': {},

    'V': {
      'Volume':
        'The total amount of a cryptocurrency traded within a specific time frame, typically measured in terms of the base currency (e.g., BTC, ETH) exchanged during trading activities.',
    },

    'W': {
      'Wallet':
        'A software or hardware tool that allows users to store, send, and receive cryptocurrencies securely. Wallets store private keys, essential for accessing and managing crypto assets. ',
      'Wei': 'The smallest unit of Ether (ETH), the native cryptocurrency of the Ethereum network. One Ether is equal to 10^18 WEI, representing the basic denomination for calculating transactions and gas fees.',
      'Whitelist':
        "A list of approved addresses or users authorized to participate in a specific cryptocurrency's ICO (Initial Coin Offering) or token sale. Whitelisting ensures that only registered and verified participants can invest in the offering.",
    },

    'X': {},

    'Y': {},

    'Z': {
      'Zero Knowledge Proof':
        'A cryptographic method that allows a party to prove possession of specific information without revealing the actual information. Zero knowledge proofs enhance privacy and security in blockchain transactions by verifying data without exposing it.',
    },
  },
];
